import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';

// import { Container } from './styles';

function Produtos() {
  const [categorias, setCategorias] = useState([]);
  const [produtos, setProdutos] = useState({
    count: 0,
    items: [],
    pages: 0,
  });
  const [load, setLoad] = useState({
    page: 1,
    hasMore: true,
  });
  const history = useHistory();

  async function handleSubmit(e) {
    try {
      const filtros = Object.entries(e).reduce((a, [key, value]) => {
        if (value) {
          return `${a}&${key}=${value}`;
        }

        return a;
      }, '');
      const { data } = await api.get(`/produtos?${filtros}`);
      setLoad({
        page: 1,
        hasMore: data.items.length,
      });
      setProdutos({ ...data, filtros });
    } catch (err) {
      toast.error(err.msg);
    }
  }

  async function loadMore(e) {
    try {
      const { data } = await api.get(
        `/produtos?page=${load.page + 1}&${produtos.filtros || ''}`
      );
      setLoad({
        page: load.page + 1,
        hasMore: data.items.length,
      });
      setProdutos((old) => ({
        ...old,
        items: old.items.concat(data.items),
      }));
    } catch (err) {
      toast.error(err.msg);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const [{ data: categoriasT }, { data }] = await Promise.all([
          api.get('/categorias'),
          api.get('/produtos'),
        ]);
        setLoad({
          page: 1,
          hasMore: data.items.length,
        });
        setCategorias(categoriasT);
        setProdutos(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, []);

  return (
    <main className='container'>
      <h6 className='mt-4'>Painel ADM</h6>
      <ul class="tab">
        <li class="tablinks">
          <Link class="link active" to='/adm'>Produtos</Link>
        </li>
        <li class="tablinks">
          <Link class="link" to='/textos'>Textos</Link>
        </li>
        <li class="tablinks">
          <Link class="link" to='/conteudos'>Conteúdos</Link>
        </li>
      </ul>
      <hr/>
      <h5 className='mt-4'>Produtos cadastrados</h5>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const data = {};
          Array.from(e?.target)
            .slice(0, 3)
            .forEach((m) => {
              data[m.id] = m.value;
            });

          handleSubmit(data);
        }}
      >
        <div className='row mt-3'>
          <div className='col-md-4 fw-normal'>
            <label for='nome' class='form-label'>
              Nome
            </label>
            <input class='form-control' id='nome' />
          </div>
          <div className='col-md-2 fw-normal'>
            <label htmlFor='categoria' class='form-label'>
              Categoria
            </label>
            <select class='form-select form-control' id='categoria'>
              <option value=''>Selecione</option>
              {categorias.map((m) => (
                <option>{m}</option>
              ))}
            </select>
          </div>
          <div className='col-md-2 fw-normal'>
            <label htmlFor='categoria' class='form-label'>
              Status
            </label>
            <select class='form-select form-control' id='status'>
              <option>Ativo</option>
              <option>Inativo</option>
              <option value='todos'>Todos</option>
            </select>
          </div>
          <div className='col-md-4 d-flex'>
            <div>
              <div>
                <label className='form-label'> &nbsp;</label>
              </div>
              <button type='submit' class='btn btn-success'>
                Consultar
              </button>
            </div>
            <div className='ml-3'>
              <div>
                <label className='form-label'> &nbsp;</label>
              </div>
              <button
                type='button'
                class='btn btn-primary'
                onClick={() => {
                  history.push('/adm/novo');
                }}
              >
                + Criar
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className='row mt-3'>
        {produtos.items.map((m) => (
          <div className='col-lg-3 mb-3'>
            <button
              className='btn-none w-100 grow h-100'
              onClick={() => {
                history.push(`/adm/novo?id=${m.id}`);
              }}
            >
              <div className='card card-body h-100'>
                <img
                  crossorigin='anonymous'
                  src={m.img_url}
                  alt={m.nome}
                  width='100%'
                  className='object-fit'
                />
                <div className='h-100 w-100 align-items-end d-flex mt-2'>
                  <p className='text-center mb-0 w-100'>
                    {m.nome}
                  </p>
                </div>
              </div>
            </button>
          </div>
        ))}
        {!!load.hasMore && (
          <div className='col-lg-12 mb-3 d-flex align-items-center justify-content-center'>
            <span>
              <button
                className='btn btn-primary-light align-items-center d-flex'
                onClick={() => {
                  loadMore();
                }}
                style={{
                  maxHeight: '100%',
                }}
              >
                <i
                  class='fa-regular fa-plus mr-3'
                  style={{
                    fontSize: 24,
                  }}
                ></i>
                <span>Carregar mais itens</span>
              </button>
            </span>
          </div>
        )}
      </div>
    </main>
  );
}

export default Produtos;
