import React from 'react';
import { useQuery } from '@tanstack/react-query';
import especialista from '../../assets/imagens/home/especialista.jpg';
import iphone from '../../assets/imagens/home/iphone.png';
import airpods from '../../assets/imagens/home/airpods.png';
import ipad from '../../assets/imagens/home/ipad.png';
import mac from '../../assets/imagens/home/mac.png';
import watch from '../../assets/imagens/home/watch.png';
import airtags from '../../assets/imagens/home/airtags.png';
import appletv from '../../assets/imagens/home/appletv.png';
import acessorios from '../../assets/imagens/home/acessorios.png';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import urlWhats from '../../utils/urlWhats';

import api from '../../services/api';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}

function Loja() {
  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:loja`],
    queryFn: () => getTexto('loja'),
  });

  return (
    <main className='d-block'>
      <div id='princ' className='container mb-5'>
        <div className='row mb-5'>
          <div className='col-md-8'>
            <p
              className="block antialiased font-sans text-lg font-normal leading-relaxed text-left"
              dangerouslySetInnerHTML={{
                __html: textos?.descricao,
              }}
            />
          </div>
          <div className='col-md-4'>
            <div className='d-flex justify-content-end'>
              <img className='avatar' src={especialista} alt='especialista' />
              <div className='mx-3 text-start mt-2'>
                <p className='mb-0 font-600 font-'>
                  Precisa de ajuda para comprar?
                </p>
                <a
                  className='decoration-none'
                  href={
                    isMobile
                      ? 'tel:+554135277898'
                      : urlWhats
                  }
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Fale com um especialista
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Iphone' className='produto'>
              <img src={iphone} alt='Iphone' />
              <span>Iphone</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/AirPods' className='produto'>
              <img src={airpods} alt='AirPods' />
              <span>AirPods</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Ipad' className='produto'>
              <img src={ipad} alt='Ipad' />
              <span>Ipad</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Mac' className='produto'>
              <img src={mac} alt='Mac' />
              <span>Mac</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Apple Watch' className='produto'>
              <img src={watch} alt='Apple Watch' />
              <span>Apple Watch</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/AirTag' className='produto'>
              <img src={airtags} alt='AirTag' />
              <span>AirTag</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Apple TV' className='produto'>
              <img src={appletv} alt='Apple TV' />
              <span>Apple TV</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Acessórios' className='produto'>
              <img src={acessorios} alt='Acessórios' />
              <span>Acessórios</span>
            </Link>
          </div>
        </div>
        {/* <div className='row mt-5 mb-3'>
          <div className='col-md-4'>
            <div className='d-flex align-items-center justify-content-center'>
              <i className='fa-solid fa-truck font-size-30 text-muted'></i>
              <div className='text-start ml-3'>
                <p className='font-600 mb-0'>Receba em casa</p>
                <p className='text-muted mb-0'>Agende a sua entrega</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='d-flex align-items-center justify-content-center'>
              <i className='fa-solid fa-box font-size-30 text-muted'></i>
              <div className='text-start ml-3'>
                <p className='font-600 mb-0'>Pronta entrega</p>
                <p className='text-muted mb-0'>Consulte os modelos</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='d-flex align-items-center justify-content-center'>
              <i className='fa-solid fa-toolbox font-size-30 text-muted'></i>
              <div className='text-start ml-3'>
                <p className='font-600 mb-0'>Assistência Técnica</p>
                <p className='text-muted mb-0'>e suporte técnico</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </main>
  );
}

export default Loja;
