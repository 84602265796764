import React from 'react';
import { useQuery } from '@tanstack/react-query';
import solucoes from '../../assets/imagens/solucoes.png';

import api from '../../services/api';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}


function Solucoes() {
  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:solucoes`],
    queryFn: () => getTexto('solucoes'),
  });

  return (
    <main>
      <article id='sobre' className='container'>
        <h1>
          <strong>Soluções</strong>
        </h1>

        <div className='shadow mb-5'>
          <img
            className='not img-sobre-2'
            src={solucoes}
            alt='apple'
          />
          <p
            className="block antialiased leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: textos?.descricao,
            }}
          />
        </div>
      </article>
    </main>
  );
}

export default Solucoes;
