import React, { useEffect, useState } from 'react';

import especialista from '../../assets/imagens/home/especialista.jpg';
import { useQuery } from '@tanstack/react-query';
import iphone from '../../assets/imagens/home/iphone.png';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useCart } from '../../contexts/cart';
import { toast } from 'react-toastify';
import api from '../../services/api';
import urlWhats from '../../utils/urlWhats';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}

function Servicos() {
  const [produtos, setProdutos] = useState({
    count: 0,
    pages: 0,
    items: [],
  });
  const [dadosModal, setDadosModal] = useState();
  const { setCarrinho } = useCart();
  const [load, setLoad] = useState({
    page: 1,
    hasMore: false,
  });

  async function loadMore(e) {
    try {
      const { data } = await api.get(
        `/produtos?page=${load.page + 1}&categoria=Serviços&${produtos.filtros || ''
        }`
      );
      setLoad({
        page: load.page + 1,
        hasMore: data.items.length >= 30,
      });
      setProdutos((old) => ({
        ...old,
        items: old.items.concat(data.items),
      }));
    } catch (err) {
      toast.error(err.msg);
    }
  }

  const handleAddCart = (item) => {
    setCarrinho((old) => {
      const exists = old.some((s) => s.id === item.id);

      if (exists) {
        return old;
      }

      const newC = old.concat(item);
      localStorage.setItem('cart', JSON.stringify(newC));

      return newC;
    });
    toast.success(`"${item.nome}" adicionado ao carrinho`);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get(`/produtos?categoria=Serviços`);
        setLoad({
          page: 1,
          hasMore: data.items.length >= 30,
        });
        setProdutos(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, []);

  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:servicos`],
    queryFn: () => getTexto('servicos'),
  });

  return (
    <main className='d-block'>
      <div id='princ' className='container mb-5'>
        <div className='row mb-5'>
          <div className='col-md-8'>
            <p
              className="block antialiased font-sans text-lg font-normal leading-relaxed text-left"
              dangerouslySetInnerHTML={{
                __html: textos?.descricao,
              }}
            />
          </div>
          <div className='col-md-4'>
            <div className='d-flex justify-content-end mt-5'>
              <img className='avatar' src={especialista} alt='especialista' />
              <div className='mx-3 text-start mt-2'>
                <p className='mb-0 font-600 font-'>
                  Precisa de ajuda para comprar?
                </p>
                <a
                  className='decoration-none'
                  href={
                    isMobile
                      ? 'tel:+554135277898'
                      : urlWhats
                  }
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Fale com um especialista
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          {produtos?.items?.map((m) => (
            <div className='col-md-3 col-sm-6 mb-3'>
              {m.nome.toLowerCase().trim() === 'aluguel de equipamentos apple' ? <a
                href={'/aluguel'}
                onClick={() => {
                  setDadosModal(m);
                }}
                className='btn-servicos produto'
              >
                <span>{m.nome}</span>
                <img src={m.img_url} alt='Iphone' />
              </a> : <a
                href={'#'}
                data-bs-toggle='modal'
                data-bs-target='#modalDetalhes'
                onClick={() => {
                  setDadosModal(m);
                }}
                className='btn-servicos produto'
              >
                <span>{m.nome}</span>
                <img src={m.img_url} alt='Iphone' />
              </a>}
            </div>
          ))}
        </div>

        {!!load.hasMore && (
          <div className='row'>
            <div className='col-lg-12 mb-3 d-flex align-items-center justify-content-center'>
              <span>
                <button
                  className='btn btn-primary-light align-items-center d-flex'
                  onClick={() => {
                    loadMore();
                  }}
                  style={{
                    maxHeight: '100%',
                  }}
                >
                  <i
                    class='fa-regular fa-plus mr-3'
                    style={{
                      fontSize: 24,
                    }}
                  ></i>
                  <span>Carregar mais itens</span>
                </button>
              </span>
            </div>
          </div>
        )}
      </div>

      <div
        class='modal modal-lg fade'
        id='modalDetalhes'
        tabindex='-1'
        aria-labelledby='modalDetalhesLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='modalDetalhesLabel'>
                {dadosModal?.nome}
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div
              class='modal-body'
              style={{
                maxHeight: 'calc(100vh - 200px)',
                overflowX: 'auto',
              }}
            >
              <div className='row'>
                <div className='d-flex justify-content-center'>
                  <img
                    crossorigin='anonymous'
                    src={dadosModal?.img_url}
                    alt={dadosModal?.nome}
                    style={{
                      width: '100%',
                      maxHeight: '50vh',
                      objectFit: 'contain',
                    }}
                  />
                </div>
                <h6 className='mt-3'>Detalhes</h6>
                <div>
                  <pre className='pre-wrap'>{dadosModal?.descricao}</pre>
                </div>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-primary-light btn-round'
                data-bs-dismiss='modal'
                onClick={() => {
                  handleAddCart(dadosModal);
                }}
              >
                Comprar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Servicos;
