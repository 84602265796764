import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Select, Image, TextArea } from '../../components/unform';
import api from '../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn button btn-success',
    cancelButton: 'button btn btn-danger',
  },
  buttonsStyling: false,
});

function Produtos() {
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const formRef = useRef();
  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(data) {
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });
    if (loading) {
      return;
    }
    try {
      formRef.current.setErrors({});
      setLoading(true);
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (query.id) {
        await api.put(`/produtos/${query.id}`, formData);
        toast.success('Produto alterado com sucesso!');
      } else {
        await api.post('/produtos', formData);
        toast.success('Produto criado sucesso!');
      }

      setLoading(false);
      Swal.close();
      history.push('/adm');
    } catch (err) {
      if (err.response?.data?.errors) {
        formRef.current.setErrors(err.response?.data?.errors);
      }
      Swal.close();
      setLoading(false);
      toast.error(err.msg);
    }
  }

  async function handleDelete() {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir esta produto ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        html: `Esta operação não poderá ser revertida`,
      })
      .then(async (result) => {
        if (result.value) {
          await deleteProduto();
        }
      });
  }
  async function deleteProduto() {
    if (!update) {
      return;
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.delete(`/produtos/${update}`);
      Swal.close();
      toast.warn('Produto excluído com sucesso!');
      history.push('/adm');
    } catch (err) {
      Swal.close();
      toast.error(err.msg);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get('/categorias');
        setCategorias(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    async function getData(id) {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/produtos/${id}`);
        document.querySelector('textarea').style.height = '500px';
        formRef.current.setData({
          ...data,
          file: data.img_url,
        });
        Swal.close();
      } catch (err) {
        Swal.close();
        toast.error('Ocorreu um erro tente novamente mais tarde');
        history.goBack();
      }
    }
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });

    if (query.id) {
      setUpdate(query.id);
      getData(query.id);
    }
  }, [location]);

  return (
    <main className='container'>
      <h5 className='mt-4'>Novo Produto</h5>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className='row'>
          <div className='col-lg-4'>
            <Image name='file' label='Foto' className='form-control' />
          </div>
          <div className='col-lg-8' />
          <div className='col-lg-4'>
            <Input name='nome' label='Nome' className='form-control' />
          </div>
          <div className='col-lg-4'>
            <Select
              name='categoria'
              label='Categoria'
              className='form-control form-select'
              data={[{ value: '', label: 'Selecione' }].concat(categorias)}
            />
          </div>
          <div className='col-lg-4'>
            <Select
              name='status'
              label='Status'
              className='form-control form-select'
              data={[
                { value: 'Ativo', label: 'Ativo' },
                { value: 'Inativo', label: 'Inativo' },
              ]}
            />
          </div>
          <div className='col-lg-12'>
            <TextArea
              name='descricao'
              label='Descrição'
              className='form-control'
            />
          </div>
        </div>
        <div className='d-flex justify-content-end mt-3 mb-3'>
          {!!update && (
            <button
              className='btn btn-danger mr-3'
              type='button'
              onClick={() => {
                handleDelete();
              }}
            >
              Excluir
            </button>
          )}
          <button className='btn btn-primary'>Salvar</button>
        </div>
      </Form>
    </main>
  );
}

export default Produtos;
