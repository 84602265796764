import React from 'react';
import { useQuery } from '@tanstack/react-query';
import sobre from '../../assets/imagens/apple.webp';

import api from '../../services/api';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}

function Sobre() {
  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:quemsomos`],
    queryFn: () => getTexto('quemsomos'),
  });

  return (
    <main>
      <article id='sobre' className='container'>
        <h1>
          <strong>Quem somos</strong>
        </h1>

        <div className='d-md-flex shadow'>
          <img className='not img-sobre' src={sobre} alt='apple' />
          <p
            className="block antialiased leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: textos?.descricao,
            }}
          />
        </div>
      </article>
    </main>
  );
}

export default Sobre;
