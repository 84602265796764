import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { Input, TextArea } from '../../components/unform';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import api from '../../services/api';



import contato from '../../assets/imagens/contato.jpg';

function Contato() {
  const formRef = useRef();

  const handleSubmit = async (e) => {
    const errors = {};

    Object.entries(e).forEach(([key, value]) => {
      console.log(key)

      if(key !== 'empresa' && key !== 'telefone' && key !== 'assunto') {
        if (
          !value
        ) {
            errors[key] = 'Obrigatório';
          }
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho!');
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/contato', e);
      Swal.close();
      toast.success('Enviado com sucesso!')
      formRef.current.reset();
    } catch (err) {
      toast.error(err.msg);
      Swal.close();
    }
  };


  return (
    <main>
      <article id='sobre' className='container'>
        <h1 className='d-flex align-items-center text-center justify-content-center'>
          <strong>Fale conosco</strong>
        </h1>

        <div className='shadow mb-5'>
          <img className='not img-sobre-vertical' src={contato} alt='apple' />
          {/* <p
            className="block antialiased leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: textos?.descricao,
            }}
          /> */}
          {/* <div className='row mt-3'>
            <div className='col ml-3'>
              <h5 className="block antialiased leading-relaxed mt-5">Formulário de Contato</h5>
              <h6 className='text-grey mb-3'><i className='fa-solid fa-warning text-muted'></i>&nbsp;&nbsp;&nbsp;Campos marcados com * são de preenchimento obrigatório.</h6>
              <Form
                ref={formRef}
                onSubmit={(e) => {
                  // handleSubmit(e);
                }}
              >
                <div class='modal-body'>
                  <div className='row'>
                    <div className='col-lg-12 mb-3'>
                      <Input
                        name={'nome'}
                        label={'Nome *'}
                        className='form-control'
                        placeholder='Informe seu nome'
                      />
                    </div>
                    <div className='col-lg-12 mb-3'>
                      <Input
                        name={'empresa'}
                        label={'Empresa'}
                        className='form-control'
                        placeholder='Empresa'
                      />
                    </div>
                    <div className='col-lg-6 mb-3'>
                      <Input
                        name='email'
                        label='Email *'
                        className='form-control'
                        placeholder='Informe seu email'
                      />
                    </div>
                    <div className='col-lg-6 mb-3'>
                      <Input
                        name='telefone'
                        label='Telefone'
                        className='form-control'
                        placeholder='(DDD) 90000-0000'
                      />
                    </div>
                    <div className='col-lg-12'>
                      <Input
                        name={'assunto'}
                        label={'Assunto'}
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-lg-12'>
                      <Input
                        name={'mensagem'}
                        label={'Mensagem *'}
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                  </div>
                </div>
                <div class='modal-footer justify-content-end mt-3'>
                  <button type='submit' class='btn btn-primary-light btn-round'>
                    Enviar
                  </button>
                </div>
              </Form>
              <br/>
            </div>
            <div className='col border-left'>
              <h5 className="block antialiased leading-relaxed mt-5">Central de Atendimento ao Cliente</h5>
              <h6 className='text-grey'><i className='fa-solid fa-phone text-muted'></i>&nbsp;&nbsp;&nbsp;(41) 3527-7898</h6>
              <h5 className="block antialiased leading-relaxed mt-5">E-mail</h5>           
              <a
                  href='mailto:comercial@rsmi.com.br'
                  className='text-grey'
                  target={'_blank'}
                  style={{
                    textDecoration: 'none',
                    fontSize: 20,
                  }}
                  rel='noreferrer'
                >
                  <i className='fa-solid fa-envelope'></i> comercial@rsmi.com.br
              </a>
              <h5 className="block antialiased leading-relaxed mt-5">Horário de Atendimento</h5>
              <h6 className='text-grey'><i className='fa-solid fa-clock text-muted'></i>&nbsp;&nbsp;&nbsp;Segunda a Sexta: Das 9:00 às 18:00</h6>
              <h5 className="block antialiased leading-relaxed mt-5">Dados da Empresa</h5>
              <h6 className='text-grey'>Rsmi Distribuidora de Equipamentos Eletrônicos LTDA <br/>CNPJ: 09.003.090/0001-49</h6>
              <h5 className="block antialiased leading-relaxed mt-5">Nosso Endereço</h5>
              <h6 className='text-grey'>Avenida João Gualberto nº 1881 - Conjunto 1802<br/>Curitiba - PR<br/>CEP: 80030001</h6>
              <br/>
            </div>
          </div>
          <br/> */}
          <div className='row ml-2 mr-2'>
              <h5 className="block antialiased leading-relaxed mt-5">Formulário de Contato</h5>
              <h6 className='text-grey mb-3'><i className='fa-solid fa-warning text-muted'></i>&nbsp;&nbsp;&nbsp;Campos marcados com * são de preenchimento obrigatório.</h6>
              <Form
                ref={formRef}
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div class='modal-body'>
                  <div className='row'>
                    <div className='col-lg-12 mb-3'>
                      <Input
                        name="nome"
                        label={'Nome *'}
                        className='form-control'
                        placeholder='Informe seu nome'
                      />
                    </div>
                    <div className='col-lg-12 mb-3'>
                      <Input
                        name="empresa"
                        label={'Empresa'}
                        className='form-control'
                        placeholder='Empresa'
                      />
                    </div>
                    <div className='col-lg-6 mb-3'>
                      <Input
                        name="email"
                        label='Email *'
                        className='form-control'
                        placeholder='Informe seu email'
                      />
                    </div>
                    <div className='col-lg-6 mb-3'>
                      <Input
                        name='telefone'
                        label='Telefone'
                        className='form-control'
                        placeholder='(DDD) 90000-0000'
                      />
                    </div>
                    <div className='col-lg-12  mb-3'>
                      <Input
                        name={'assunto'}
                        label={'Assunto'}
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-lg-12'>
                      <TextArea
                        name={'mensagem'}
                        label={'Mensagem *'}
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                  </div>
                </div>
                <div class='modal-footer justify-content-end mt-3'>
                  <button type='submit' class='btn btn-primary-light btn-round'>
                    Enviar
                  </button>
                </div>
              </Form>
            </div>
            <br/>
        </div>
      </article>
    </main>
  );
}

export default Contato;
