import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useCart } from '../../contexts/cart';

function Produto() {
  const [produtos, setProdutos] = useState({
    count: 0,
    pages: 0,
    items: [],
  });
  const [dadosModal, setDadosModal] = useState();
  const params = useParams();
  const { setCarrinho } = useCart();
  const [load, setLoad] = useState({
    page: 1,
    hasMore: false,
  });

  async function loadMore(e) {
    try {
      const { data } = await api.get(
        `/produtos?page=${load.page + 1}&categoria=${params?.produto}&${
          produtos.filtros || ''
        }`
      );
      setLoad({
        page: load.page + 1,
        hasMore: data.items.length >= 30,
      });
      setProdutos((old) => ({
        ...old,
        items: old.items.concat(data.items),
      }));
    } catch (err) {
      toast.error(err.msg);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get(
          `/produtos?categoria=${params?.produto}`
        );
        setLoad({
          page: 1,
          hasMore: data.items.length >= 30,
        });
        setProdutos(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, [params]);

  const handleAddCart = (item) => {
    setCarrinho((old) => {
      const exists = old.some((s) => s.id === item.id);

      if (exists) {
        return old;
      }

      const newC = old.concat(item);
      localStorage.setItem('cart', JSON.stringify(newC));

      return newC;
    });
    toast.success(`"${item.nome}" adicionado ao carrinho`);
  };

  return (
    <div>
      <main className='container'>
        <article id='sobre' className='mb-5'>
          {/* <div class='mb-3 d-flex-wrap align-item-center justify-content-between'>
            <h1 class='text-capitalize'>{params.produto}</h1>
            <span
              class='mt-1'
              style={{
                position: 'relative',
              }}
            >
              <i
                class='fa-solid fa-magnifying-glass'
                style={{
                  zIndex: 1,
                  position: 'absolute',
                  left: 7,
                  top: 12,
                  fontSize: 13,
                  color: '#818182',
                }}
              ></i>
              <input
                type='text'
                placeholder='Digite para buscar'
                style={{
                  border: 'none',
                  zIndex: 0,
                  paddingLeft: 30,
                  boxShadow: '2px 4px 12px rgb(0 0 0 / 6%)',
                  borderRadius: 8,
                  height: 35,
                  fontSize: 14,
                  minWidth: 30,
                }}
              />
            </span>
          </div> */}
          <h1 className='text-capitalize'>{params.produto}</h1>
          <div className='row'>
            {produtos.items?.map((m) => (
              <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
                <button type='button' className='btn-none w-100 h-100'>
                  <div className='card card-body grow h-100'>
                    <div className='mb-2'>
                      <span className='text-product'>{m.nome}</span>
                    </div>

                    <div className='h-100 w-100 justify-content-center align-items-end d-flex'>
                      <button
                        className='btn-none'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#modalDetalhes'
                        onClick={() => {
                          setDadosModal(m);
                        }}
                      >
                        <img
                          crossorigin='anonymous'
                          src={m.img_url}
                          alt={m.nome}
                          width={'100%'}
                        />
                      </button>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                      <button
                        className='btn btn-outline-primary btn-sm btn-round'
                        data-bs-toggle='modal'
                        data-bs-target='#detalhes'
                        onClick={() => {
                          setDadosModal(m);
                        }}
                      >
                        + Detalhes
                      </button>
                      <button
                        className='btn btn-primary-light btn-sm btn-round'
                        data-bs-toggle='modal'
                        data-bs-target='#modalDetalhes'
                        onClick={() => {
                          handleAddCart(m);
                        }}
                      >
                        Comprar
                      </button>
                    </div>
                  </div>
                </button>
              </div>
            ))}
            {!!load.hasMore && (
              <div className='col-lg-12 mb-3 d-flex align-items-center justify-content-center'>
                <span>
                  <button
                    className='btn btn-primary-light align-items-center d-flex'
                    onClick={() => {
                      loadMore();
                    }}
                    style={{
                      maxHeight: '100%',
                    }}
                  >
                    <i
                      class='fa-regular fa-plus mr-3'
                      style={{
                        fontSize: 24,
                      }}
                    ></i>
                    <span>Carregar mais itens</span>
                  </button>
                </span>
              </div>
            )}
          </div>
        </article>
      </main>
      <div
        class='modal modal-lg fade'
        id='detalhes'
        tabindex='-1'
        aria-labelledby='detalhes'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' >
                {dadosModal?.nome}
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div
              class='modal-body'
              style={{ maxHeight: 'calc(100vh - 200px)', overflowX: 'auto' }}
            >
              <div className='d-flex justify-content-center'>
                <img
                  crossorigin='anonymous'
                  src={dadosModal?.img_url}
                  alt={dadosModal?.nome}
                  style={{
                    width: '100%',
                    maxHeight: '50vh',
                    objectFit: 'contain',
                  }}
                />
              </div>
              <h6 className='mt-3'>Detalhes</h6>
              <div>
                <pre className='pre-wrap'>{dadosModal?.descricao}</pre>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-primary-light btn-round'
                data-bs-dismiss='modal'
                data-bs-toggle='modal'
                data-bs-target='#modalDetalhes'
                onClick={() => {
                  handleAddCart(dadosModal);
                }}
              >
                Comprar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Produto;
