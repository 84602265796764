import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/scss/index.scss'
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'react-quill/dist/quill.snow.css';

const tagManagerArgs = {
  gtmId: 'GTM-PJ4Q5FS'
}

ReactPixel.init('948008726256064');
// ReactGA.initialize('G-JNBXLHKSHM');
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
