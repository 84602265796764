import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Input({
  name,
  label,
  type,
  className = '',
  group = false,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (type === 'file') {
          return ref.files[0];
        }
        if (type === 'number') {
          return Number(ref.value);
        }
        return ref.value;
      },
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: (ref) => {
        ref.value = '';
      },
    });
  }, [fieldName, registerField, type]);

  if (group) {
    return (
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        type={type || 'text'}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      />
    );
  }

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>

      <input
        ref={inputRef}
        defaultValue={defaultValue}
        type={type || 'text'}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      />

      {error && <small className="error">{error}</small>}
    </div>
  );
}
