import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useParams } from 'react-router-dom';

function Conteudo() {
  const [dados, setDados] = useState();
  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get(`/conteudos/${params.slug}/slug`);
        setDados(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, [params]);

  return (
    <>
      <main className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-10'>

            <h1 className='text-header my-3 mb-0 pb-0'>
              <strong>{dados?.titulo}</strong>
            </h1>
            <small className='text-muted '>{new Date(dados?.createdAt).toLocaleDateString('pt-BR')}</small>
            <div className='mt-3 d-flex'>
              <img className='object-fit' width='100%' src={dados?.img_url} alt={dados?.titulo} />
            </div>

            <article className='mt-5 ql-editor' dangerouslySetInnerHTML={{
              __html: dados?.descricao
            }} />
          </div>
        </div>
      </main>
    </>
  );
}

export default Conteudo;
