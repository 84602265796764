import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import Layout from './layout';
import Home from './views/home';
import Sobre from './views/sobre';
import Solucoes from './views/solucoes';
import Financial from './views/financial';
import Loja from './views/loja';
import Servicos from './views/servicos';
import Produto from './views/loja/produto';
import Carrinho from './views/carrinho';
import Produtos from './views/adm';
import Textos from './views/adm/textos';
import Novo from './views/adm/novo';
import Login from './views/login';
import { AuthProvider, useAuth } from './contexts/auth';
import { CartProvider } from './contexts/cart';
import Agradecimento from './views/agradecimento';
import Aluguel from './views/servicos/aluguel';
import Conteudos from './views/adm/conteudos';
import NovoConteudo from './views/adm/novoConteudo';
import Noticias from './views/conteudos/noticias';
import Conteudo from './views/conteudos';
import Blog from './views/conteudos/blog';
import Contato from './views/contato';

const AuthRoutes = () => {
  return (
    <AuthProvider>
      <CartProvider>
        <Routes />
      </CartProvider>
    </AuthProvider>
  );
};

const Routes = () => {
  const { loading, signed } = useAuth();

  if (loading) {
    return <div>Carregando</div>;
  }

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/sobre' exact component={Sobre} />
          <Route path='/loja' exact component={Loja} />
          <Route path='/loja/:produto' exact component={Produto} />
          <Route path='/solucoes' exact component={Solucoes} />
          <Route path='/financial' exact component={Financial} />
          <Route path='/servicos' exact component={Servicos} />
          <Route path='/aluguel' exact component={Aluguel} />
          <Route path='/carrinho' exact component={Carrinho} />
          <Route path='/compra-finalizada' exact component={Agradecimento} />
          <Route path='/noticias' exact component={Noticias} />
          <Route path='/blog' exact component={Blog} />
          <Route path='/conteudo/:slug' exact component={Conteudo} />
          <Route path='/contato' exact component={Contato} />

          {signed ? (
            <>
              <Route path='/adm' exact component={Produtos} />
              <Route path='/adm/novo' exact component={Novo} />
              <Route path='/textos' exact component={Textos} />
              <Route path='/conteudos' exact component={Conteudos} />
              <Route path='/conteudos/novo' exact component={NovoConteudo} />
            </>
          ) : (
            <>
              <Redirect from='/adm' to='/login' />
              <Redirect from='/adm/novo' to='/login' />
              <Route path='/login' exact component={Login} />
            </>
          )}

          <Redirect to='/' />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default AuthRoutes;
