import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/cart';
// import { Container } from './styles';
import appleReseller from '../assets/imagens/apple-autorized.webp';
import logo from '../assets/imagens/logo4.png';

function Header() {
  const { count } = useCart();
  const [showDropDown, setShowDropDown] = useState(false);

  // function mudouTamanho() {
  //   if (window.innerWidth >= 768) {
  //     itens.style.display = 'block';
  //   } else {
  //     itens.style.display = 'none';
  //   }
  // }

  function clickMenu() {
    const itens = document.getElementById('itens');
    if (itens.style.display === 'block') {
      itens.style.display = 'none';
    } else {
      itens.style.display = 'block';
    }
  }

  function closeMenu() {
    const itens = document.getElementById('itens');
    itens.style.display = 'none';
    if (showDropDown) {
      setShowDropDown(false)
    }
  }

  return (
    <header>
      <div className='mobile-menu'>
        <h1>
          <Link to='/'>
            <img src={logo} alt='logo' />
            {/* <img src={appleReseller} height={40} alt="logo apple reseller" style={{
              marginBottom: 10,
              marginLeft: 20,
            }} /> */}
          </Link>
        </h1>
        <div className='d-flex align-items-center'>
          <button className='btn-none' onClick={() => clickMenu()}>
            <i className='fa-solid fa-bars' id='burguer'></i>
          </button>
        </div>
      </div>
      <menu id='itens' className='mb-0 mt-0'>
        <ul className='mb-0'>
          <li>
            <Link onClick={closeMenu} to='/'>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={closeMenu} to='/sobre'>Quem somos</Link>
          </li>
          <li>
            <Link onClick={closeMenu} to='/loja'>Loja</Link>
          </li>
          <li>
            <Link onClick={closeMenu} to='/servicos'>Serviços</Link>
          </li>
          <li>
            <Link onClick={closeMenu} to='/solucoes'>Soluções</Link>
          </li>
          <li>
            <Link onClick={closeMenu} to='/financial'>Serviços financeiros</Link>
          </li>
          <li>
            <Link onClick={closeMenu} to='/contato'>Fale conosco</Link>
          </li>
          <li className='hide-lg'>
            <Link onClick={closeMenu} to='/blog'>Blog</Link>
          </li>
          <li className='hide-lg'>
            <Link onClick={closeMenu} to='/noticias'>Notícias</Link>
          </li>
          <li className='hide-md' onMouseOver={() => {
            if (!showDropDown) {
              setShowDropDown(true)
            }
          }} onMouseLeave={() => {
            setShowDropDown(false)
          }}>
            <div class="dropdown">
              <button className="btn-none dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Conteúdos
              </button>
              <div class="dropdown-menu" style={{
                display: showDropDown ? 'block' : 'none'
              }}>
                <Link className="dropdown-item" onClick={closeMenu} to='/blog'>Blog</Link>
                <Link className="dropdown-item" onClick={closeMenu} to='/noticias'>Notícias</Link>
              </div>
            </div>
          </li>
          <li>
            <a
              href={
                isMobile
                  ? 'tel:+554135277898'
                  : 'https://api.whatsapp.com/send/?phone=554135277898&text=Ol%C3%A1%2C%20estou%20no%20site%20da%20RSMI%20Business%20e%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o.&app_absent=0'
              }
              onClick={closeMenu}
              target={'_blank'}
              rel='noreferrer'
            >
              (41) 3527-7898
            </a>
          </li>
          <li>
            <Link onClick={closeMenu} to='/carrinho'>
              <span className='position-relative'>
                <span class='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                  {count}
                  <span class='visually-hidden'>count items no carrinho</span>
                </span>
                <i className='fa-solid fa-cart-shopping'></i> Carrinho
              </span>
            </Link>
          </li>

        </ul>
      </menu>
    </header>
  );
}

export default Header;
