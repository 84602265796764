import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [carrinho, setCarrinho] = useState([]);

  useEffect(() => {
    api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const err = error?.response?.data?.error;
        error.msg =
          (err && typeof err === 'string' && err) ||
          'Ocorreu algum problema, tente novamente mais tarde!';

        if (401 === error.response.status) {
          setUser(null);
          localStorage.clear();
        }

        return Promise.reject(error);
      }
    );
    const { token, usuario } = localStorage;
    if (token && usuario) {
      const _user = JSON.parse(usuario);
      api.defaults.headers.Authorization = `Bearer ${token}`;

      setUser(_user);
      setLoading(false);
    }
    setLoading(false);
  }, []);

  async function signIn({ senha, email }) {
    try {
      const res = await api.post('/sessions', {
        email,
        senha,
      });

      const { user, token } = res.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;
      localStorage.setItem(
        'usuario',
        JSON.stringify({
          email,
          nome: user.nome,
        })
      );
      localStorage.setItem('token', token);

      setUser({
        email,
        nome: user.nome,
      });
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  function signOut() {
    localStorage.setItem('token', null);
    localStorage.setItem('usuario', null);
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        carrinho,
        setCarrinho,
        signIn,
        signOut,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
