import React from 'react';
import { useQuery } from '@tanstack/react-query';
import especialista from '../../assets/imagens/home/especialista.jpg';
import iphone from '../../assets/imagens/home/iphone.png';
import airpods from '../../assets/imagens/home/airpods.png';
import ipad from '../../assets/imagens/home/ipad.png';
import mac from '../../assets/imagens/home/mac.png';
import watch from '../../assets/imagens/home/watch.png';
import airtags from '../../assets/imagens/home/airtags.png';
import appletv from '../../assets/imagens/home/appletv.png';
import acessorios from '../../assets/imagens/home/acessorios.png';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import banner1 from '../../assets/imagens/banner1.png';
import banner2 from '../../assets/imagens/banner2.png';
import banner3 from '../../assets/imagens/banner3.png';
import sobre from '../../assets/imagens/sobre_sem.png';
import urlWhats from '../../utils/urlWhats';

import api from '../../services/api';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}


function Home() {
  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:loja`],
    queryFn: () => getTexto('loja'),
  });

  return (
    <main className='d-block'>
      <div
        id='carouselExampleDark'
        className='carousel slide'
        data-bs-ride='carousel'
      >
        <div className='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#carouselExampleDark'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
          ></button>
          <button
            type='button'
            data-bs-target='#carouselExampleDark'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          ></button>
          <button
            type='button'
            data-bs-target='#carouselExampleDark'
            data-bs-slide-to='2'
            aria-label='Slide 3'
          ></button>
          <button
            type='button'
            data-bs-target='#carouselExampleDark'
            data-bs-slide-to='3'
            aria-label='Slide 4'
          ></button>
        </div>
        <div className='carousel-inner'>
        <div className='carousel-item active'>
            <Link to={'/loja/Iphone'}>
              <img
                src={banner1}
                className='d-block w-100'
                alt='...'
              />
            </Link>
          </div>
        <div className='carousel-item'>
            <Link to={'/sobre'}>
              <img
                src={sobre}
                className='d-block w-100'
                alt='...'
              />
            </Link>
          </div>
          <div className='carousel-item indicator-dark'>
            <Link to={'/loja/Mac'}>
              <img
                src={banner2}
                className='d-block w-100'
                alt='...'
              />
            </Link>
          </div>
          <div className='carousel-item'>
            <Link to={'/loja/Apple%20Watch'}>
              <img
                src={banner3}
                className='d-block w-100'
                alt='...'
              />
            </Link>
          </div>
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselExampleDark'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselExampleDark'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
      <div id='princ' className='container mb-5'>
        <div className='row mb-5'>
          <div className='col-md-8'>
            <p
              className="block antialiased font-sans text-lg font-normal leading-relaxed text-left"
              dangerouslySetInnerHTML={{
                __html: textos?.descricao,
              }}
            />
          </div>
          <div className='col-md-4'>
            <div className='d-flex justify-content-end'>
              <img className='avatar' src={especialista} alt='especialista' />
              <div className='mx-3 text-start mt-2'>
                <p className='mb-0 font-600 font-'>
                  Precisa de ajuda para comprar?
                </p>
                <a
                  className='decoration-none'
                  href={
                    isMobile
                      ? 'tel:+554135277898'
                      : urlWhats
                  }
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Fale com um especialista
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Iphone' className='produto'>
              <img src={iphone} alt='Iphone' />
              <span>Iphone</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/AirPods' className='produto'>
              <img src={airpods} alt='AirPods' />
              <span>AirPods</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Ipad' className='produto'>
              <img src={ipad} alt='Ipad' />
              <span>Ipad</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Mac' className='produto'>
              <img src={mac} alt='Mac' />
              <span>Mac</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Apple Watch' className='produto'>
              <img src={watch} alt='Apple Watch' />
              <span>Apple Watch</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/AirTag' className='produto'>
              <img src={airtags} alt='AirTag' />
              <span>AirTag</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Apple TV' className='produto'>
              <img src={appletv} alt='Apple TV' />
              <span>Apple TV</span>
            </Link>
          </div>
          <div className='col-md-3 col-sm-6'>
            <Link to='/loja/Acessórios' className='produto'>
              <img src={acessorios} alt='Acessórios' />
              <span>Acessórios</span>
            </Link>
          </div>
        </div>
        {/* <div className='row mt-5'>
          <div className='col-md-4'>
            <div className='d-flex align-items-center justify-content-center'>
              <i className='fa-solid fa-truck font-size-30 text-muted'></i>
              <div className='text-start ml-3'>
                <p className='font-600 mb-0'>Receba em casa</p>
                <p className='text-muted mb-0'>Agende a sua entrega</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='d-flex align-items-center justify-content-center'>
              <i className='fa-solid fa-box font-size-30 text-muted'></i>
              <div className='text-start ml-3'>
                <p className='font-600 mb-0'>Pronta entrega</p>
                <p className='text-muted mb-0'>Consulte os modelos</p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='d-flex align-items-center justify-content-center'>
              <i className='fa-solid fa-toolbox font-size-30 text-muted'></i>
              <div className='text-start ml-3'>
                <p className='font-600 mb-0'>Assistência Técnica</p>
                <p className='text-muted mb-0'>e suporte técnico</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </main>
  );
}

export default Home;
