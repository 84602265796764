import React, { useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import solucoes from '../../assets/imagens/solucoes.png';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { Input } from '../../components/unform';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}

function Aluguel() {
  const formRef = useRef();
  const [tipoPessoa, setPessoa] = useState('juridica');

  const handleChange = (e) => {
    setTimeout(() => {
      if (e.target.checked) {
        setPessoa(e.target.id);
      }
    }, 100);
  };

  const handleSubmit = async (e) => {
    const errors = {};

    Object.entries(e).forEach(([key, value]) => {
      if (
        !value
      ) {
        errors[key] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho!');
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/cotacao-alugel', e);
      document.querySelector('.btn-close')?.click?.();
      Swal.close();
      window.location.href = '/compra-finalizada'
    } catch (err) {
      toast.error(err.msg);
      Swal.close();
    }
  };

  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:aluguel`],
    queryFn: () => getTexto('aluguel'),
  });

  return (
    <main>
      <article id='sobre' className='container'>
        <h1>
          <strong>Aluguel de equipamentos Apple</strong>
        </h1>

        <div className='mb-5'>
          <img
            className='not img-sobre-2'
            src={solucoes}
            alt='apple'
          />
          <p className='pre-wrap'>
            <p
              className="block antialiased font-sans leading-relaxed text-md"
              dangerouslySetInnerHTML={{
                __html: textos?.descricao,
              }}
            />
          </p>
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-primary-light btn-round'
              data-bs-toggle='modal'
              data-bs-target='#modalCarrinhoAluguel'
            >
              Consultar valores
            </button>
          </div>
        </div>
      </article>

      <div
        class='modal modal-lg fade'
        id='modalCarrinhoAluguel'
        tabindex='-1'
        aria-labelledby='modalCarrinhoAluguelLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='modalCarrinhoAluguelLabel'>
                Cadastre seus dados
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <Form
              ref={formRef}
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div class='modal-body'>
                <div className='row'>
                  <div className='col-lg-12 mb-3'>
                    <Input
                      name={'nome'}
                      label={'Nome'}
                      className='form-control'
                      placeholder='Informe seu nome'
                    />
                  </div>
                  <div className='col-lg-6 mb-3'>
                    <Input
                      name={'razao_social'}
                      label={'Razão Social'}
                      className='form-control'
                      placeholder='Razão Social'
                    />
                  </div>
                  <div className='col-lg-6 mb-3'>
                    <Input
                      name='cnpj'
                      label='CNPJ'
                      className='form-control'
                      placeholder='CNPJ'
                    />
                  </div>
                  <div className='col-lg-6 mb-3'>
                    <Input
                      name='email'
                      label='Email'
                      className='form-control'
                      placeholder='Informe seu email'
                    />
                  </div>
                  <div className='col-lg-6 mb-3'>
                    <Input
                      name='telefone'
                      label='Telefone'
                      className='form-control'
                      placeholder='(DDD) 90000-0000'
                    />
                  </div>
                  <div className='col-lg-12'>
                    <Input
                      name={'mensagem'}
                      label={'Mensagem'}
                      className='form-control'
                      placeholder=''
                    />
                  </div>
                </div>
              </div>
              <div class='modal-footer justify-content-between'>
                <button type='button' class='btn btn-dark-light btn-round' data-bs-toggle='modal'
                  data-bs-target='#modalCarrinhoAluguel'>
                  Cancelar
                </button>
                <button type='submit' class='btn btn-primary-light btn-round'>
                  Enviar dados para orçamento
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main >
  );
}

export default Aluguel;
