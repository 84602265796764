import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Link } from 'react-router-dom';

function Blog() {
  const [dados, setDados] = useState({
    count: 0,
    items: [],
    pages: 1,
    page: 1,
  });

  async function loadMore(e) {
    try {
      const { data } = await api.get(
        `/conteudos?page=${dados.page + 1}&tipo=blog&${dados.filtros || ''}`
      );
      setDados((old) => ({
        ...old,
        items: old.items.concat(data.items),
      }));
    } catch (err) {
      toast.error(err.msg);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get('/conteudos?tipo=blog');
        setDados(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, []);
  return (
    <>
      <main className='container'>
        <h1 className='text-header my-3'>
          <strong>Blog</strong>
        </h1>

        <div className='card'>
          <div className='row mt-3 mb-3 justify-content-center'>
            {dados.items.map((m) => (
              <div className='col-lg-10 hr-bottom pb-3'>
                <Link
                  className='btn-none w-100 grow h-100 text-decoration-none'
                  to={`/conteudo/${m?.slug}`}
                >
                  <div className='h-100'>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <img
                          crossorigin='anonymous'
                          src={m.img_url}
                          alt={m.titulo}
                          height={150}
                          width='100%'
                          className='object-cover'
                        />
                      </div>
                      <div className='col-lg-7'>
                        <p className='text-muted'>{new Date(m?.created_at).toLocaleDateString('pt-BR')}</p>
                        <h5 className='text-center mb-0 w-100'>
                            {m.titulo}
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className='row'>
            {dados?.page && dados?.page < dados.pages &&
              <div className='col-lg-12 mb-3 d-flex align-items-center justify-content-center'>
                <span>
                  <button
                    className='btn btn-primary-light align-items-center d-flex'
                    onClick={() => {
                      loadMore();
                    }}
                    style={{
                      maxHeight: '100%',
                    }}
                  >
                    <i
                      class='fa-regular fa-plus mr-3'
                      style={{
                        fontSize: 24,
                      }}
                    ></i>
                    <span>Carregar mais itens</span>
                  </button>
                </span>
              </div>
            }
          </div>
        </div>
      </main>

    </>
  );
}

export default Blog;
