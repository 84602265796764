import React from 'react';
import { toast } from 'react-toastify';
import { useCart } from '../../contexts/cart';

function Carrinho() {
  const { carrinho, setCarrinho } = useCart();

  const handleDelCart = (item) => {
    setCarrinho((old) => {
      const newC = old.filter((s) => s.id !== item.id);
      localStorage.setItem('cart', JSON.stringify(newC));

      return newC;
    });
    toast.success(`"${item.nome}" removido do carrinho`);
  };

  return (
    <>
      <main className='container'>
        <h1 className='text-header my-3'>
          <strong>Carrinho</strong>
        </h1>
        <h3 className='text-muted' hidden={carrinho.length !== 0}>
          Seu carrinho está vazio
        </h3>
        <div className='card'>
          <table class='table' hidden={carrinho.length === 0}>
            <thead>
              <tr>
                <th scope='col'>Imagem</th>
                <th scope='col'>Produto</th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {carrinho?.map((m) => (
                <tr key={m.id}>
                  <td>
                    <div
                      className='position-relative'
                      style={{
                        width: 80,
                        height: 80,
                      }}
                    >
                      <img
                        src={m.img_url}
                        className='product-image__img'
                        alt={m.nome}
                        crossorigin='anonymous'
                      />
                    </div>
                  </td>
                  <td>
                    <h6 className='mt-3'>{m.nome}</h6>
                  </td>
                  <td>
                    <button
                      className='btn btn-outline-danger btn-sm mt-3'
                      onClick={() => {
                        handleDelCart(m);
                      }}
                    >
                      <i class='fa-solid fa-trash'></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-primary-light btn-round'
                      data-bs-toggle='modal'
                      data-bs-target='#modalDetalhes'
                    >
                      Finalizar compra
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </main>
     
    </>
  );
}

export default Carrinho;
