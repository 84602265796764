import React from 'react';
import { useQuery } from '@tanstack/react-query';
import financial from '../../assets/imagens/financial.png';
import financialLogo from '../../assets/imagens/financial-logo.jpg';
// import { Container } from './styles';

import api from '../../services/api';

async function getTexto(categoria = '') {
  console.log(categoria)
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}

function Financial() {
  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:servicosfinanceiros`],
    queryFn: () => getTexto('servicosfinanceiros'),
  });

  return (
    <main>
      <article id='sobre' className='container'>
        <h1 className='d-flex align-items-center text-center justify-content-center'>
          <strong>Serviços financeiros</strong>
          &nbsp;
          <img
            className='not'
            width={80}
            style={{
              borderRadius: 15,
            }}
            src={financialLogo}
            alt='apple'
          />
        </h1>

        <div className='shadow mb-5'>
          <img className='not img-sobre-vertical' src={financial} alt='apple' />
          <p
            className="block antialiased leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: textos?.descricao,
            }}
          />
        </div>
      </article>
    </main>
  );
}

export default Financial;
