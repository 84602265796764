import React, { createContext, useContext, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Checkbox, Input, Select } from '../components/unform';
import api from '../services/api';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [carrinho, setCarrinho] = useState([]);

  useEffect(() => {
    const cart = localStorage.getItem('cart');
    if (cart) {
      try {
        const parsedCart = JSON.parse(cart);
        console.log(parsedCart);
        if (!Array.isArray(parsedCart)) {
          throw new Error('Objeto inválido');
        }
        setCarrinho(parsedCart);
      } catch (err) {
        console.log(err);
        localStorage.setItem('cart', null);
      }
    }
  }, []);

  const [tipoPessoa, setPessoa] = useState('juridica');
  const formRef = useRef();
  const history = useHistory();


  const handleSubmit = async (e) => {
    const errors = {};

    Object.entries(e).forEach(([key, value]) => {
      if (
        !value
      ) {
        errors[key] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho!');
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/cotacao', {
        dados: e,
        produtos: carrinho.map((m) => m.id),
      });
      setCarrinho([]);
      localStorage.setItem('cart', null);
      document.querySelector('.btn-close')?.click?.();
      Swal.close();
      window.location.href = '/compra-finalizada'

      // history.push('/compra-finalizada');
    } catch (err) {
      toast.error(err.msg);
      Swal.close();
    }
  };

  const consultarCep = async (data = '') => {
    const cep = data.replace(/\D/g, '');
    if (cep.length !== 8) {
      return;
    }
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data: resultCep } = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${cep}`
      );
      if (resultCep) {
        formRef.current.setFieldValue('logradouro', resultCep?.street);
        formRef.current.setFieldValue('estado', resultCep?.state);
        formRef.current.setFieldValue('cidade', resultCep?.city);
        formRef.current.setFieldValue('bairro', resultCep?.neighborhood);
      }
      Swal.close();
    } catch (err) {
      formRef.current.setFieldValue('logradouro', '');
      formRef.current.setFieldValue('estado', '');
      formRef.current.setFieldValue('cidade', '');
      formRef.current.setFieldValue('bairro', '');
      toast.warning('CEP não encontrado!');
      Swal.close();
    }
  };

  const handleChange = (e) => {
    setTimeout(() => {
      if (e.target.checked) {
        setPessoa(e.target.id);
      }
    }, 100);
  };

  return (
    <CartContext.Provider
      value={{
        carrinho,
        setCarrinho,
        count: carrinho?.length || 0,
      }}
    >
      {children}
      <div
        class='modal modal-lg fade'
        id='modalDetalhes'
        tabindex='-1'
        aria-labelledby='modalDetalhesLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='modalDetalhesLabel'>
                Cadastre seus dados
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <Form
              ref={formRef}
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div class='modal-body'>
                <div>
                  <div class='form-check-inline'>
                    <input
                      class='form-check-input'
                      type='radio'
                      name='tipoPessoa'
                      id='juridica'
                      defaultChecked
                      onFocus={(e) => handleChange(e)}
                    />
                    <label class='form-check-label ml-1' for='juridica'>
                      Pessoa Jurídica
                    </label>
                  </div>
                  <div class='form-check-inline'>
                    <input
                      class='form-check-input'
                      type='radio'
                      name='tipoPessoa'
                      id='fisica'

                      onFocus={(e) => handleChange(e)}
                    />
                    <label class='form-check-label ml-1' for='fisica'>
                      Pessoa Física
                    </label>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <Input
                      name={'nome'}
                      label={'Nome'}
                      className='form-control'
                      placeholder='Informe seu nome'
                    />
                  </div>
                  {tipoPessoa === 'fisica' ? (
                    <>
                      <div className='col-lg-6'>
                        <Input
                          name='cpf'
                          label='CPF'
                          className='form-control'
                          placeholder='CPF'
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-lg-6'>
                        <Input
                          name='cnpj'
                          label='CNPJ'
                          className='form-control'
                          placeholder='CNPJ'
                        />
                      </div>
                    </>
                  )}
                  <div className='col-lg-6'>
                    <Input
                      name='email'
                      label='Email'
                      className='form-control'
                      placeholder='Informe seu email'
                    />
                  </div>
                  <div className='col-lg-6'>
                    <Input
                      name='telefone'
                      label='Telefone'
                      className='form-control'
                      placeholder='(DDD) 90000-0000'
                    />
                  </div>
                </div>
              </div>
              <div class='modal-footer justify-content-between'>
                <button type='button' class='btn btn-dark-light btn-round' data-bs-toggle='modal'
                  data-bs-target='#modalDetalhes'>
                  Continuar comprando
                </button>
                <button type='submit' class='btn btn-primary-light btn-round'>
                  Enviar dados para compra
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </CartContext.Provider>
  );
};

export function useCart() {
  const context = useContext(CartContext);

  return context;
}
