import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/imagens/logo4.png';
// import { Container } from './styles';

function Footer() {
  return (
    <footer>
      <div className='container'>
        <div className='rodape'>
          <div className='logo mt-2'>
            <p id='navp1' className='d-grid justify-content-center'>
              <Link to='/'>
                <img src={logo} alt='logo' />
              </Link>
            </p>
          </div>
          <div className='ender text-grey'>
            <a
              href='https://www.google.com/maps/place/Av.+Jo%C3%A3o+Gualberto,+1881+-+Alto+da+Gl%C3%B3ria,+Curitiba+-+PR,+80030-001/@-25.4119091,-49.258048,17z/data=!3m1!4b1!4m5!3m4!1s0x94dce4241a584c27:0xe542303b4b36459!8m2!3d-25.4119091!4d-49.258048'
              target='blank'
              style={{
                marginBottom: '1px',
                textAlign: 'center',
                textDecoration: 'none',
                color: '#6e6e73',
              }}
            >
              <p
                className='small mb-1'
                style={{
                  color: '#fff',
                }}
              >
                Avenida João Gualberto, 1881 - Conjunto 1802 - Juvevê -
                Curitiba(PR) - CEP 80030-001
              </p>
            </a>
            <p className='small mb-0'>
              Horário de Funcionamento: Segunda a sexta, das 9h às 18h
            </p>
          </div>
          <div className='redes mt-2'>
            <nav>
              <a
                href='https://www.facebook.com/people/rsmi_business/100087569058046/'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-facebook-f'></i>
              </a>
              <a
                href='https://www.instagram.com/rsmi_business/'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-instagram'></i>
              </a>
              <a
                href='https://br.linkedin.com/company/rsmi-business'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-linkedin'></i>
              </a>
            </nav>
          </div>
        </div>
        <div className='contato d-md-flex d-grid pb-2'>
          <p id='navp2' className='mb-0 d-none d-md-flex'>
            <a
              href='https://www.xcoding.com.br?ref=rsmi.business'
              className='text-white'
              target={'_blank'}
              style={{
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
              rel='noreferrer'
            >
              <span
                style={{
                  color: '#6e6e73',
                }}
              >
                Made with ❤️ by
              </span>
              &nbsp; Xcoding
            </a>
          </p>

          <div className='d-md-flex d-grid'>
            <div className='mr-2'>
              <a
                href='mailto:comercial@rsmi.com.br'
                className='text-grey'
                target={'_blank'}
                style={{
                  textDecoration: 'none',
                  fontSize: 15,
                }}
                rel='noreferrer'
              >
                <i className='fa-solid fa-envelope'></i> comercial@rsmi.com.br
              </a>
              &nbsp;
            </div>
            <div>
              <a
                href='tel:+554135277898'
                className='text-grey'
                target={'_blank'}
                style={{
                  textDecoration: 'none',
                  fontSize: 15,
                }}
                rel='noreferrer'
              >
                <i className='fa-solid fa-phone'></i> +55(41) 3527-7898
              </a>
            </div>
          </div>

          <p id='navp2' className='mb-1 mt-1 d-md-none'>
            <a
              href='https://www.xcoding.com.br?ref=rsmi.business'
              className='text-white'
              target={'_blank'}
              style={{
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
              rel='noreferrer'
            >
              <span
                style={{
                  color: '#6e6e73',
                }}
              >
                Made with ❤️ by
              </span>
              &nbsp; Xcoding
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
