import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';


import { toast } from 'react-toastify';
import { Select, Editor } from '../../components/unform';
import api from '../../services/api';

function TextosCategorias() {
  const formRef = useRef();
  const [categoria, setCategoria] = useState();

  useEffect(() => {
    async function getData() {
      const { data } = await api.get(
        `/textos-categorias?categoria=${categoria}`
      );
      formRef.current.setFieldValue('descricao', data?.descricao);
    }
    if (categoria) {
      getData();
    }
  }, [categoria]);

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast.error('Verifique os campos obrigatórios!');
      return;
    }

    try {
      await api.post('/textos-categorias', data);

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      toast.error('Erro ao slavar dados!');
    }
  }

  return (
    <main className='container'>
      <h6 className='mt-4'>Painel ADM</h6>
      <ul class="tab">
        <li class="tablinks">
          <Link class="link" to='/adm'>Produtos</Link>
        </li>
        <li class="tablinks">
          <Link class="link active" to='/textos'>Textos</Link>
        </li>
        <li class="tablinks">
          <Link class="link" to='/conteudos'>Conteúdos</Link>
        </li>
      </ul>
      <hr/>
      <h5 className='mt-4'>Textos site</h5>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className='row mt-3'>
          <div className='col-lg-12 fw-normal'>
            <Select
              name="categoria"
              label="Categoria"
              className="form-control"
              onChange={(e) => {
                formRef.current.setFieldValue('descricao', '');
                setCategoria(e?.target?.value);
              }}
              data={[
                {
                  label: 'Selecione',
                  value: '',
                },
                {
                  label: 'Quem somos',
                  value: 'quemsomos',
                },
                {
                  label: 'Loja',
                  value: 'loja',
                },
                {
                  label: 'Serviços',
                  value: 'servicos',
                },
                {
                  label: 'Serviços - Aluguel',
                  value: 'aluguel',
                },
                {
                  label: 'Soluções',
                  value: 'solucoes',
                },
                {
                  label: 'Serviços financeiros',
                  value: 'servicosfinanceiros',
                },
              ]}
            />
          </div>
          <div className='col-lg-12 fw-normal'>
            <Editor
              readOnly={!categoria}
              name="descricao"
              label="Descrição"
            />
          </div>
        </div>
        <div className='row mt-3 mb-3'>
          <div className='col-lg-12 fw-normal'>
            <button className='btn btn-primary' type='submit'>Salvar</button>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default TextosCategorias;
