import React from 'react';
import especialista from '../../assets/imagens/logo-rsmi.png'

function Agradecimento() {
  return <main className='container d-flex align-items-center justify-content-center'>
    <span>
      <h1 className='text-header text-center'>
        <strong>Obrigado pela escolha!</strong>
      </h1>
      <div className='d-flex-wrap-md align-items-center justify-content-center'>
        <img src={especialista} height={150}  className='object-fit mb-2' alt="especialista"/>
        <div className='ml-3'>
          <h3 style={{
            color: '3c3c3e',
            textAlign: 'center'
          }}>
            Em breve um dos nossos especialistas entrará em contato. Conte conosco para receber soluções personalizadas e qualidade excepcional.
          </h3>
          <h4 style={{
            color: 'rgb(73 73 78)',
            marginTop: 30,
            textAlign: 'center'
          }}>
            Nosso atendimento é de segunda a sexta-feira, das 9h às 18h.
          </h4>
        </div>
      </div>
    </span>
  </main>
}

export default Agradecimento;