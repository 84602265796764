import React from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';


// import { Container } from './styles';

function Login() {
  const { signIn } = useAuth();
  const history = useHistory();
  const handleSubmit = async (e) => {
    try {
      await signIn(e);
      history.push('/adm')
    } catch (err) {
      toast.error(err.msg);
    }
  };

  return (
    <main className='container'>
      <div className='form-signin w-100 h-100 m-auto'>
        <form
          className='mt-5'
          onSubmit={(e) => {
            e.preventDefault();
            const data = {};
            Array.from(e?.target)
              .slice(0, 2)
              .forEach((m) => {
                data[m.id] = m.value;
              });

            handleSubmit(data);
          }}
        >
          <h1 className='h3 mb-3 fw-normal'>Acessar painel</h1>

          <div className='form-floating'>
            <input
              type='email'
              className='form-control'
              id='email'
              placeholder='name@example.com'
            />
            <label htmlFor='email'>Email</label>
          </div>
          <div className='form-floating'>
            <input
              type='password'
              className='form-control'
              id='senha'
              placeholder='Password'
            />
            <label htmlFor='senha'>Senha</label>
          </div>

          <button className='w-100 btn btn-lg btn-primary mt-3' type='submit'>
            Login
          </button>
        </form>
      </div>
    </main>
  );
}

export default Login;
